import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

// eslint-disable-next-line
export const PeoplePageTemplate = ({
  title,
  helmet,
  content,
  contentComponent,
  people,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section">
      {helmet || ""}
      <div className="container content content-border">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <hr />
            <PostContent content={content} />
            {people && people.length ? (
              <div style={{ marginTop: `4rem` }}>
                <div className="">
                  {people.map((people) => (
                    <div
                      key={people.name + `people`}
                      className="simple-border has-text-centered columns is-multiline is-tablet people-container"
                    >
                      <div className="person column is-6-tablet">
                        <h4>{people.name + " " + people.credentials}</h4>
                        <PreviewCompatibleImage
                          imageInfo={people.photo}
                          style={{ width: "auto" }}
                        />
                        <br />
                        <h4>{people.position}</h4>
                      </div>
                      <div className="column is-6-tablet">
                        <p className="top-margin-tablet">{people.blurb}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

PeoplePageTemplate.propTypes = {
  helmet: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  people: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      credentials: PropTypes.string,
      position: PropTypes.string,
      photo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      blurb: PropTypes.string,
    })
  ),
};

const People = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <PeoplePageTemplate
        helmet={
          <Helmet titleTemplate="%s | Pathlab">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        content={post.html}
        contentComponent={HTMLContent}
        people={post.frontmatter.people}
      />
    </Layout>
  );
};

People.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default People;

export const pageQuery = graphql`
  query PeopleByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        people {
          name
          credentials
          position
          photo {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 526, quality: 92)
              }
            }
          }
          blurb
        }
      }
    }
  }
`;
